@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body{
  background-color: #FFFCE1;
}
.backcolor {
    background-color: rgba(0, 0, 0, 0.5);
  }

input[type="checkbox"] {
    accent-color: #fae006;
}
.nav-name{
  text-overflow: ellipsis;
}
  .pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
    border-collapse: collapse;
  }
  
  .pagination a {
    padding: 7px 15px;
    border: 1px solid #D1D5DB;
    color: #000;
  }
  
  .pagination__link--active a {
    color: #000;
    background: #fcdd00;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }

.select option:hover{
  background-color: #fcdd00;
}

/* loader css */

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #FCDD00;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* health tips img  */
.content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}
.content .content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.7s ease-in-out 0s;
  -moz-transition: all 0.7s ease-in-out 0s;
  transition: all 0.7s ease-in-out 0s;
}
.content:hover .content-overlay{
  opacity: 1;
}
.content-image{
  width: 100%;
}
.content-details {
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: x-large;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.7s ease-in-out 0s;
  -moz-transition: all 0.7s ease-in-out 0s;
  transition: all 0.7s ease-in-out 0s;
}

.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3{
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p{
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom{
  top: 80%;
}

/* for chat page click here animation */
@keyframes moveLeftToRight {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0);
  }
}

.animation-container {
  width: 200px;
  height: 100px;
  animation: moveLeftToRight 3s infinite;
  animation-fill-mode: forwards;
}

/* for table height  */
@media only screen and (min-width: 1281px) {
  .table-hieght{
    height: 685px;
  }
}

/* to zoom logo image  */
.logo-img{
  transition: all 1s ease-in-out;
 
}
.logo-img:hover{
  transform: scale(3);
}

/* for date input */
.input-container {
  position: relative;
}

input[type="date"] {
  /* Reset styles for Firefox */
  appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  /* Custom styles */
  position: relative;
  z-index: 1;
}

/* Style the calendar icon (you can use an image or other styling here) */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
}

/* Style the calendar icon for Firefox */
input[type="date"]::-moz-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
}

/* for responsive react range date in report  */
@media (max-width: 600px) {
  .rdrMonthAndYearWrapper,
  .rdrWeekDay,
  .rdrDateDisplayWrapper,
  .rdrSelectionPreview,
  .rdrFooter {
    display: none;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
}
/* .rdrDay .rdrDayNumber span::after{
  color: #000 !important;
} */
.rdrDayToday .rdrDayNumber span:after{
  background: #FCDD00 !important;
}
.rdrMonthAndYearPickers select:hover{
  background: #FFFCE1 !important;
}
/* for outline in mozella  */
input:-moz-focusring,
textarea:-moz-focusring {
  outline: solid #FCDD00;
}

.min-width-200 {
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
}

/* for react date piicker  */
.react-datepicker__day:hover {
  background-color: #FFFCE1  !important;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px;
  color: #000;
}

.react-datepicker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
  font-family: "Inter" !important;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--light) !important;
  background: #FCDD00 !important;
}

.react-datepicker__current-month {
  color: #000 !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  border: solid 2px var(--brand) !important;
  background-color: white !important;
  color: #FCDD00 !important;
  width: 30px;
  height: 30px;

}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 7px;
  background-color: #FCDD00;
  color: white;
}
.react-datepicker__day--outside-month {
  color: gray !important;
  pointer-events: none;
}

/* video call css  */
.main >div>div:nth-child(2){
  background-color: #FCDD00 !important;
}
.main >div>div>div>div:nth-child(1){
  background-color: transparent !important;
}
.main >div>div>div:nth-child(3){
  background-color: rgb(239 97 85) !important;
}
.main ._3Sxu7{
  position: absolute;
  bottom: 70px;
  right: 0;
  height: 18vh;
  width: 15vw;
  overflow:hidden !important;
}

.main ._3Sxu7>div:nth-child(1) {
  min-height: 27vh !important;
  min-width: 27vh !important;
  margin: 0 !important;
}

.black-bg{
  z-index: 20 !important;
}
/* radio btn  */
input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 2px solid black;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 70%;
  height: 70%;
  margin: 15% auto;
  border-radius: 50%;
  background-color: #fff;
}

input[type="radio"]:checked:before {
  background: #FCDD00;
}

input[type="radio"]:checked {
border-color: black;
}











  